import {
  ErrorMappingInterface,
  ValidationFormErrorInterface
} from './interfaces'

const translations = {
  addressType: {
    avenue: 'Avenida',
    garden: 'Jardim',
    patio: 'Pátio',
    plaza: 'Praça',
    square: 'Quadra',
    street: 'Rua'
  },
  agency: {
    cnpj: 'CNPJ',
    edit: 'Editar dados da imobiliária',
    name: 'Nome',
    title: 'Imobiliária'
  },
  leads: {
    title: 'Leads',
    add: 'Cadastrar lead',
    gestao: 'Gestão de Leads',
    historico: 'Histórico de Leads',
    listagemHistorico: 'Listagem de histórico',
    data: 'Data',
    codImovel: 'Cód do imóvel',
    status: 'Status',
    nome: 'Nome',
    actions: 'Ações',
    cadastrar: 'Cadastrar Lead',
    imovel: 'Imóvel',
    telefone: 'Telefone',
    origem: 'Origem',
    whatsApp: 'WhatsApp',
    interesse: 'Interesse',
    cancelar: 'Cancelar',
    salvar: 'Salvar Lead',
    buscar: 'Buscar'
  },
  banners: {
    commercialType: {
      button: 'Ver todos os imóveis',
      title: 'Olá empreendedor!',
      titleComplement: 'Procura um local para sua empresa?'
    },
    helloStudent: {
      button: 'Ver todos os imóveis',
      title: 'Olá estudante!',
      titleComplement: 'Procura um apê bem localizado?'
    },
    rightPlace: {
      button: 'Quero cadastrar meu imóvel',
      title: 'O lugar certo para a valorização do seu imóvel.'
    },
    simpleRent: {
      button: 'Cadastre seu imóvel',
      title: 'Alugue seu imóvel de forma rápida e simples',
      subtitle: 'Alugar seu imóvel nunca foi tão fácil'
    },
    quickRent: {
      button: 'Faça uma proposta',
      title: 'Agilidade e facilidade',
      subtitle:
        'Sem burocracia na hora de alugar um imóvel próximo de faculdades e mercados'
    }
  },
  contracts: {
    add: 'Novo contrato',
    copasaCemig: 'Copasa/Cemig',
    create: 'Gerar contrato',
    deliveryOfKeys: 'Entrega das chaves e manual',
    digitalizedContract: 'Contrato digitalizado',
    edit: 'Editar Contrato',
    entryInspection: 'Inspeção',
    entryInspectionSignature: 'Aprovação da Inspeção',
    registrationData: 'Dados do contrato',
    rentPaymentDay: 'Dia do pagamento',
    status: {
      filling: 'Em Preenchimento',
      active: 'Ativo',
      in_legal: 'Jurídico',
      in_vacancy: 'Em Desocupação',
      terminated: 'Rescindido'
    } as Record<string, string>,
    tenantAssignedContract: 'Assinatura do locatário',
    tenantBirthplace: 'Local de nascimento',
    tenantCountryOfBirth: 'Nacionalidade',
    tenantDocuments: 'Documentos do locatário',
    tenantMaritalStatus: 'Estado civil',
    tenantOccupation: 'Ocupação',
    title: 'Contratos',
    open: 'Abrir contrato',
    ownerAssignedContract: 'Assinatura do locador'
  },
  dashboard: {
    activeProperties: 'Imóveis ativos',
    activeContracts: 'Contratos ativos',
    canceledContracts: 'Contratos cancelados',
    closedContracts: 'Contratos fechados',
    realStateCollected: 'Imóveis captados',
    lastCreatedProperties: 'Últimos imóveis cadastrados',
    newProperties: 'Imóveis captados',
    title: 'Dashboard'
  },
  employee: {
    add: 'Novo funcionário',
    edit: 'Editar funcionário'
  },
  errors: {
    currencyRegex: 'Formato inválido para um campo monetário - X,XX',
    errorBoundary: 'Ops... Aconteceu um erro... Tente novamente.',
    expiredToken: 'Token expirado',
    fileLimit: (size: string) => `O tamanho máximo do arquivo deve ser ${size}`,
    floatRegex: 'Formato inválido para um campo percentual - X,XX',
    userAlreadyExists: 'Já existe um usuário com este e-mail'
  },
  financial: {
    title: 'Financeiro'
  },
  form: {
    errors: {
      badInput: () => `Formato inválido`,
      patternMismatch: (error: ValidationFormErrorInterface) =>
        `${inputNameFormat(error.inputName)} inválido`,
      tooLong: (error: ValidationFormErrorInterface) =>
        `Digite até ${error.maxLength} caracteres`,
      tooShort: (error: ValidationFormErrorInterface) =>
        `Digite pelo menos ${error.minLength} caracteres`,
      valueMissing: () => `Campo obrigatório`,
      rangeOverflow: (error: ValidationFormErrorInterface) =>
        `Deve ser menor ou igual que ${error.max}`,
      rangeUnderflow: (error: ValidationFormErrorInterface) =>
        `Deve ser maior ou igual que ${error.min}`,
      stepMismatch: (error: ValidationFormErrorInterface) =>
        `Menor valor possível é ${error.step}`,
      typeMismatch: (error: ValidationFormErrorInterface) =>
        `Digite um ${error.inputType}`
    } as ErrorMappingInterface,
    reset: 'Limpar',
    submit: 'Enviar'
  },
  general: {
    active: 'Ativo',
    add: 'Adicionar',
    addPhoto: 'Adicionar nova foto',
    addFiles: 'Adicionar novos arquivos',
    address: 'Endereço',
    all: 'Todos',
    back: 'Voltar',
    birthdate: 'Data de nascimento',
    cancel: 'Cancelar',
    apply: 'Aplicar',
    category: 'Categoria',
    clear: 'Limpar',
    checkAll: 'Marcar todos',
    checkDetails: 'Ver detalhes',
    city: 'Cidade',
    close: 'Fechar',
    code: 'Código',
    contact: 'Contato',
    contracts: 'Contratos',
    createdAt: 'Criado em',
    data: 'Dados',
    dataSavedSuccess: 'Dados salvos com sucesso!',
    description: 'Descrição',
    details: 'Detalhes',
    documents: 'Documentos',
    edit: 'Editar',
    email: 'E-mail',
    phone: 'Telefone',
    endDate: 'Data de término',
    filter: 'Filtros',
    initDate: 'Data de início',
    justification: 'Justificativa',
    lastUpdatedAt: 'Última atualização',
    leasedRealState: 'Imóveis locados',
    logo: 'SeuLugar Logo',
    logout: 'Sair',
    maxPrice: 'Preço máximo',
    month: 'Mês',
    name: 'Nome',
    next: 'Próximo',
    noResultsFound: 'Não encontramos resultados para a sua busca...',
    notFilled: 'Não informado',
    number: 'Número',
    observation: 'Observações',
    openNewTab: 'Abrir',
    ownProperties: 'Imóveis próprios',
    photos: 'Fotos',
    price: 'Preço',
    prices: 'Preços',
    proposals: 'Propostas',
    property: 'Imóvel',
    rejectReason: 'Motivo de recusa',
    removePhoto: 'Remover foto',
    removeFile: 'Remover arquivo',
    save: 'Salvar',
    search: 'Buscar',
    select: 'Selecionar',
    send: 'Enviar',
    state: 'Estado',
    status: 'Situação',
    type: 'Tipo',
    update: 'Atualizar',
    updatedAt: 'Atualizado em',
    uncheckAll: 'Desmarcar todos',
    deleteProperty: 'Excluir imóvel',
    infoPhoto: 'Clique em uma foto para usar como capa',
    saveProperty: 'Salvar imóvel',
    priceNegotiation: 'Preço e Negociação',
    amenities: 'Comodidades',
    imagesAndDocuments: 'Imagens e Documentos',
    pickup: 'Captador',
    propertyPublication: 'Publicação do Imóvel',
    errorZipcode: 'Erro ao buscar dados',
    requiredFields: 'Preencha todos os campos obrigatórios'
  },
  login: {
    copyright: (year: number) =>
      `© ${year} - seu.lugar - Todos os direitos reservados`,
    createUser: 'Criar conta Seu.Lugar',
    errors: {
      passwordMustContainNumber: `A senha deve conter pelo menos um número`,
      passwordMustContainUpperCase: `A senha deve conter pelo menos uma letra maiúscula`,
      passwordMustContainLowerCase: `A senha deve conter pelo menos uma letra minúscula`,
      passwordMustHaveLength: (length: number) =>
        `A senha deve conter pelo menos ${length} caracteres`,
      passwordMustMatch: 'As senhas não são iguais'
    },
    forgotPassword: 'Esqueci minha senha',
    password: 'Senha',
    newPassword: 'Nova Senha',
    confirmNewPassword: 'Confirmar Nova Senha',
    recoverPassword: 'Recuperar senha',
    signin: 'Entrar'
  },
  properties: {
    add: 'Novo imóvel',
    addNewProposal: 'Criar proposta',
    address: 'Endereço do imóvel',
    addressType: 'Tipo',
    additionalParkingSpace: 'Vaga adicional de garagem',
    alreadyHaveProposal: 'Já possui uma proposta ativa',
    administrationFee: 'Taxa de administração',
    approximateValue: 'Valor aproximado',
    area: 'Área',
    bailInsurance: 'Seguro fiança',
    bathrooms: 'Banheiros',
    categories: {
      commercial: 'Comercial',
      residential: 'Residencial'
    } as Record<string, string>,
    checkWebsite: 'Ver imóvel no site',
    condominium: 'Condomínio',
    description: 'Dados do imóvel',
    edit: 'Editar imóvel',
    estimated: 'Estimados',
    footage: 'Metragem',
    fireInsurance: 'Seguro incêndio',
    includedInCondominium: 'Itens inclusos no condomínio',
    intermediationFee: 'Taxa de intermediação',
    iptu: 'IPTU',
    itemsIncludedInCondominium: {
      reserveFund: 'Fundo de reserva',
      water: 'Água'
    },
    invalidZipCode: 'Formato de CEP inválido',
    locationReference: 'Complemento',
    minimumPrice: 'Valor mínimo aceito',
    neighborhood: 'Bairro',
    noPhoto: 'Arraste as fotos do imóvel para esse quadro ou clique no botão abaixo para selecionar',
    noFile: 'Ainda não há arquivos adicionados',
    addFile: 'Arraste os documentos do imóvel para esse quadro ou clique no botão abaixo para selecionar',
    parkingSpaces: 'Vagas de garagem',
    propertyData: 'Dados do imóvel',
    propertyDoesNotHaveContracts: 'O imóvel não possui contratos',
    propertyDoesNotHaveActiveContract: 'O imóvel não possui contrato ativo',
    propertyDoesNotHaveProposals: 'O imóvel não possui propostas',
    propertyNotRented: 'O imóvel ainda não foi locado',
    propertyNotOwned: 'O imóvel não possui dono',
    otherValues: 'Demais valores',
    rent: 'Aluguel',
    registerPayment: 'Registrar pagamento',
    rooms: 'Quartos',
    sendPhoto: 'Enviar fotos',
    status: {
      available: 'Disponível para locação',
      awaitingSignature: 'Aguardando assinaturas',
      inactive: 'Inativo',
      pendingPhoto: 'Aguardando fotos',
      active: 'Ativo',
      pending: 'Pendente',
      proposalInAnalysis: 'Proposta em análise',
      rent: 'Imóvel locado'
    },
    targetPrice: 'Valor anunciado',
    title: 'Imóveis',
    total: 'Total',
    types: {
      apartment: 'Apartamento',
      house: 'Casa',
      kitnet: 'Kitnet',
      land: 'Terreno',
      room: 'Sala',
      shed: 'Galpão',
      store: 'Loja',
      studio: 'Studio'
    } as Record<string, string>,
    zipCode: 'CEP',
    propertyOwner: 'Proprietário',
    indicateOrRegister: 'Deseja  indicar ou cadastrar um imóvel?',
    indicate: 'Indicar',
    register: 'Cadastrar',
    mensageNewPhoto: 'Para adicionar uma foto capa, clique em alguma image',
    mensagePhotoIsPrimary:
      'Para marcar uma nova foto como capa, delete a capa atual!',
    infoOwner: 'Informar proprietário',
    notInfoOwner: 'Não informar proprietário'
  },
  proposals: {
    add: 'Nova proposta',
    edit: 'Editar proposta',
    title: 'Propostas',
    proposalData: 'Dados da proposta',
    status: {
      accepted: 'Aceita',
      filling: 'Em Preenchimento',
      insuranceAnalysis: 'Em Análise - Seguro',
      valueAnalysis: 'Em Análise - Valor',
      rejected: 'Recusada'
    } as Record<string, string>
  },
  roles: {
    admin: 'Administrador',
    customer: 'Usuário',
    employee: 'Funcionário'
  } as Record<string, string>,
  settings: {
    title: 'Configurações'
  },
  site: {
    home: {
      commercialProperties: {
        title: 'Imóveis comerciais',
        description:
          'Você pode encontrar apartamentos, casas e pontos comerciais para aluguel.',
        link: 'Quero ver todos os pontos comerciais'
      },
      lastRegisteredProperties: {
        description:
          'Nossos imóveis são todos certificados por profissionais da área e cada um deles tem sua documentação válida para aluguel.',
        title: 'Últimos imóveis cadastrados'
      },
      seeAlso: {
        description:
          'Você pode encontrar apartamentos, casas e pontos comerciais para aluguel.',
        title: 'Veja também',
        link: 'Quero ver todos os imóveis'
      }
    },
    favorites: {
      favoriteList: 'Lista de imóveis favoritos',
      favoriteListDescription:
        'Estes são os imóveis que você mais gostou e salvou para analisar depois.',
      mainBannerTitle: 'Seus imóveis favoritos',
      mainBannerSubtitle: 'Veja seus "queridinhos" da plataforma'
    },
    filter: {
      title: 'Imobiliária',
      subTitle: 'descomplicada',
      search: 'Filtro'
    },
    logo: {
      text: 'Seu imóvel sem burocracia'
    }
  },
  users: {
    add: 'Novo usuário',
    cpfCnpj: 'CPF/CNPJ',
    documents: 'Documentos',
    edit: 'Editar usuário',
    errors: {
      cpfFormat: 'Somente números ou XXX.XXX.XXX-XX',
      validContact: 'Somente números ou (XX)YXXXX-XXXX',
      duplicateContact:
        'Esse número de contato já foi adicionado anteriormente. Por favor, verifique sua lista de contatos.'
    },
    fullName: 'Nome/Razão social',
    new: 'Criar usuário',
    newest: 'Mais recentes',
    noPhones: 'Não há telefones cadastrados',
    ownedProperties: 'Imóveis próprios',
    permissions: 'Permissões',
    personalData: 'Dados pessoais',
    ownerData: 'Dados do proprietário',
    phones: 'Telefones',
    phone: 'Telefone',
    registrationData: 'Dados cadastrais',
    rentedProperties: 'Imóveis alugados',
    rg: 'RG',
    role: 'Papel',
    search: 'Busque por um usuário',
    status: {
      active: 'Ativo',
      inactive: 'Inativo'
    } as Record<string, string>,
    title: 'Usuários',
    types: {
      owner: 'Proprietário',
      tenant: 'Locatário'
    } as Record<string, string>,
    userNotOwnerProperty: 'O usuário não é dono de imóvel',
    userNotRentedProperty: 'O usuário não tem imóvel alugado',
    viewDetails: 'Ir para detalhes do usuário',
    otherInfo: 'Informações adicionais',
    saveAndAdvance: 'Salvar e avançar',
    registerOwner: 'Cadastrar proprietário'
  },
  visits: {
    title: 'Visitas'
  },
  stepsOwner: {
    searchOwner: 'Buscar Proprietário',
    searchNameCpf: 'Busque por nome ou cpf já cadastrado',
    registerOwner: 'Cadastrar proprietário',
    registerNewOwner: 'Cadastrar novo proprietário',
    name: 'Nome',
    phoneOne: 'Telefone 1',
    phoneTwo: 'Telefone 2',
    WhatsApp: 'WhatsApp',
    email: 'Email',
    cpfCnpj: 'CPF/CNPJ',
    rg: 'RG',
    birthdate: 'Data de nascimento',
    saveOwner: 'Salvar Proprietário'
  },
  condominiumLabels: {
    title: 'Condomínios cadastrados',
    name: 'Nome',
    add: 'Novo condomínio',
    responsible: 'Responsável',
    contact: 'Contato',
    numbersOfImoveis: 'Número de Imóveis',
    neighborhood: 'Bairro',
    actions: 'Ações',
    last: 'Mais recente',
    older: 'Mais antigo'
  },
  comodidadesTitle: {
    imovelOferece: 'O que seu Imóvel oferece?',
    caracteristicasImovel: 'Quais as características do imóvel?',
    tipoLazerImovel: 'Qual tipo de lazer do imóvel?',
    loalizacaoArmarios: 'Onde estão instalados os armários?',
    tipoAquecimento: 'Qual tipo de aquecimento?',
    tipoDePiso: 'Qual tipo de piso',
    tipoLazerCondominio: 'Qual lazer do condomínio?',
    infraCondominio: 'Qual a infraestrutura do seu condomínio?'
  },
  advancedFilter: {
    federalityUnity: 'Unidade Federativa',
    city: 'Cidade',
    neighborhood: 'Bairros',
    min: 'Mínimo',
    max: 'Máximo',
    tipoImovel: 'Tipo do Imóvel',
    finalidade: 'Finalidade',
    status: 'Status',
    qtdQuartos: 'Quantidade de Quartos'
  },
  propertyDescription: {
    cep: 'CEP',
    notCep: 'Não sei meu CEP',
    publicPlace: 'Logradouro',
    number: 'Número',
    neighborhood: 'Bairro',
    block: 'Bloco',
    locationReference: 'Complemento',
    city: 'Cidade',
    state: 'Estado',
    condominium: 'Condomínio',
    selectCondominium: 'Selecione o condomínio',
    registerCondominium: 'Cadastrar condomínio',
    cantSay: 'Não sei dizer',
    commercialDistrict: 'Bairro comercial',
    detailedInformation: 'Informações detalhadas',
    address: 'Endereço',
    purposeProperty: 'Finalidade do imóvel',
    propertyType: 'Tipo do imóvel',
    propertySubtype: 'Subtipo do imóvel',
    totalArea: 'Área total',
    totalAreaTooltip: 'Área total é a soma de todas as áreas do imóvel, sendo ela maior que as demais',
    usefulArea: 'Área útil',
    usefulAreaTooltip: 'Área útil é aquela interior ao imóvel, sendo a somatória das áreas dos cômodos internos desconsiderando garagens, varandas e paredes',
    privateArea: 'Área privativa',
    commonArea: 'Área comum',
    commonAreaTooltip: 'São áreas com acesso comum, tanto dos moradores quanto dos convidados. São considerados, por exemplo, saguões, áreas de lazer e piscinas no cálculo',
    rightFoot: 'Pé direito',
    solarPosition: 'Posição Solar',
    floorNumber: 'Número do andar',
    occupation: 'Ocupação',
    locationKeys: 'Local das chaves do imóvel',
    specifyBelow: 'Especifique abaixo',
    petAccepted: 'Aceita pet',
    furnished: 'Mobiliado',
    characteristics: 'Características',
    bedrooms: 'Quartos',
    howManySuites: 'Sendo quantas suítes',
    bathroom: 'Banheiros',
    bathroomTooltip: 'Considerando suítes, se existirem',
    toilet: 'Lavabos',
    rooms: 'Salas',
    parkingSpaces: 'Vagas de garagem',
    garageIdentifier: 'Identificador da vaga de garagem'
  },
  priceNegotiation: {
    sale: 'Venda',
    rent: 'Locação',
    businessType: 'Tipo de negócio',
    youCanSelectBothOptions: 'Você pode selecionar as duas opções',
    saleValue: 'Valor de venda',
    proprietaryFreeValue: 'Valor livre do proprietário',
    rentalValue: 'Valor da locação',
    tax: 'Taxas/Impostos',
    condominiumValue: 'Valor do condomínio',
    IptuValue: 'Valor do IPTU',
    ItrValue: 'Valor do ITR',
    IptuInstallment: 'Parcelamento do IPTU',
    warrantyTypes: 'Tipos de garantia',
    negotiation: 'Negociação',
    minimumSalesValue: 'Valor mínimo de venda',
    maximumSalesValue: 'Valor máximo de venda',
    acceptFinancing: 'Aceita financiamento',
    minimumRentalAmount: 'Valor mínimo da locação',
    maximumRentalAmount: 'Valor máximo da locação',
    acceptExchange: 'Aceita permuta',
    administrationFee: 'Taxa de administração',
    intermediationFee: 'Taxa de intermediação',
    negotiationDetails: 'Detalhes da negociação',
    placeholderTextArea:
      'Informe aqui alguma particularidade da negociação, como por exemplo, imóvel de herança, financiamento pendente e etc...',
    confidentialInformation: 'Informações confidenciais',
    numberIptu: 'Número do IPTU',
    propertyRegistration: 'Matrícula do imóvel',
    energyMeterCode: 'Código do medidor de energia',
    waterMeterCode: 'Código do hidrômetro',
    gasMeterCode: 'Código do medidor de gás',
    writingSituation: 'Situação da escritura',
    registryOffice: 'Cartório de registro',
    dwell: 'Habite-se'
  },
  propertyPublication: {
    terms: 'Termos',
    hasPublicationTerm: 'Possui termo de publicação?',
    hasPropertySign: 'Possui placa no imóvel?',
    adStatus: 'Status do anúncio',
    sendTerm: 'Enviar termo de aceite ao proprietário',
    announcement: 'Anúncio',
    announcementDescription:
      'Escreva um texto detalhado do seu imóvel, destacando seus pontos fortes e diferenciais',
    title: 'Título',
    placeholderTitle:
      'Digite aqui um título para o anúncio e o card do seu imóvel...',
    propertyDescription: 'Descrição do imóvel',
    placeholderPropertyDescription: 'Faça uma descrição do imóvel...'
  },
  pickup: {
    searchPickup: 'Buscar captador',
    placeholderSearchPickup: 'Pesquise por nome ou cpf já cadastrados',
    isIndication: 'Este imóvel foi indicado por alguém',
    searchCoPickup: 'Buscar Co-captador',
    registerPickup: 'Cadastrar captador',
    registerNewPickup: 'Cadastrar novo captador',
    registerCoPickup: 'Cadastrar co-captador',
    registerNewCoPickup: 'Cadastrar novo co-captador',
    savePickup: 'Salvar captador',
    saveCoPickup: 'Salvar co-captador'
  },
  registerCondominium: {
    new: 'Novo Condomínio',
    edit: 'Editar Condomínio',
    nameCondominium: 'Nome do condomínio',
    administrator: 'Administradora',
    registerAdministrator: 'Cadastrar administradora',
    phone: 'Telefone',
    email: 'Email',
    zipCode: 'CEP',
    neighborhood: 'Bairro',
    typeCondominium: 'Tipo de condomínio',
    saveCondominium: 'Salvar condomínio',
    notCep: 'Não sei meu cep',
    publicPlace: 'Logradouro',
    number: 'Número',
    state: 'Estado',
    referencePoint: 'Ponto de referência',
    city: 'Cidade',
    save: 'Salvar Condomínio'
  }
}

const inputNameFormat = (inputName: string | undefined) => {
  switch (inputName) {
    case 'email-condominio':
      return 'email'
    default:
      return inputName
  }
}

export default translations
